import axios from 'axios';
import jwt_decode from 'jwt-decode';

let jwt = window.localStorage.getItem("jwt");

export default {
    isAuthenticated() {
        return jwt != null;
    },

    isAdmin() {
        try {
            let jwtDecoded = jwt_decode(jwt);
            return jwtDecoded.user_role == "admin";
        }
        catch (Error) {
            return false;
        }
    },

    getUserId() {
        try {
            let jwtDecoded = jwt_decode(jwt);
            return jwtDecoded.user_id;
        }
        catch (Error) {
            return null;
        }
    },

    getToken() {
        return jwt;
    },

    async login(username, password) {
        const token = document.querySelector('[name="csrf-token"]').content;
        let config = {
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        }
        const response = await axios.post("/api/v1/sessions", {
            username: username,
            password: password
        }, config);
        jwt = response.data.jwt;
        window.localStorage.setItem("jwt", jwt);

    },

    logout() {
      jwt = null;
      window.localStorage.removeItem("jwt");  
    },

    
    get_credentials() {
        return axios({
            method: "GET",
            url: "/api/v1/get_aws_credentials"
        });
    },
}