import React from "react";
import { render } from "react-dom";
import App from "../components/App";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-163931521-1');
ReactGA.pageview(window.location.pathname + window.location.search);

document.addEventListener("DOMContentLoaded", () => {
  let mainDiv = document.createElement("div")
  mainDiv.className = "mainDiv"
  render(
    <App />,
    document.body.appendChild(mainDiv)
  );
});