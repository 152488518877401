import React from "react";
import { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from './Routes/PrivateRoute';
import AdminRoute from './Routes/AdminRoute';

const Home = React.lazy(() => import("./Home"));
const NewUser = React.lazy(() => import("./Auth/NewUser"));
const Login = React.lazy(() => import("./Auth/Login"));
const MainHeader = React.lazy(() => import("./Components/MainHeader"));
const Footer = React.lazy(() => import("./Components/Footer"));
const UsersProfile = React.lazy(() => import("./User/UserProfile"));
const AllPosts = React.lazy(() => import("./Blog/Index"));
const NewPost = React.lazy(() => import("./Blog/NewPost"));
const EditPost = React.lazy(() => import("./Blog/EditPost"));
const SettingsRouter = React.lazy(() => import("./Settings/SettingsRouter"));
const Post = React.lazy(() => import("./Blog/Post"));
import Loading from "./Loading";
import auth from "../services/auth";

export default class App extends React.Component {
    constructor(props) {
      super(props)
      
      this.state = {
        isAuthenticated: auth.isAuthenticated()
      }
    }
  
    render() {
      return (
        <Suspense fallback={<Loading />}>
          <Router>
            <Route path="/" render={(props) => (props.location.pathname !== "/") && 
                <MainHeader updateAuth={this.updateAuth.bind(this)} logout={this.logout.bind(this)} isAuthenticated={this.state.isAuthenticated} />}> 
            </Route>
            <Switch>
              <Route path="/" exact component={Home} />
              <AdminRoute path="/signup" component={NewUser}/>
              <Route path="/login" render={(props) => <Login {...props} updateAuth={this.updateAuth.bind(this)} />} />
              <Route path="/profile/:id" exact component={UsersProfile} />
              <Route path="/blog" exact component={AllPosts} />
              <Route exact path="/blog/:id" component={Post} />
              <PrivateRoute exact path="/new_post" component={NewPost} />
              <PrivateRoute exact path="/blog/edit/:id" component={EditPost} />
              <PrivateRoute path="/settings" component={SettingsRouter} />
            </Switch>
            <Footer logout={this.logout.bind(this)} isAuthenticated={this.state.isAuthenticated} />
          </Router>
        </Suspense>
      )
    }
  
    updateAuth() {
      this.setState({
          isAuthenticated: auth.isAuthenticated()
      })
    }
    
    logout(e) {
      e.preventDefault(),
      auth.logout();
      this.updateAuth();
    }
}