import React from 'react';
import loadingGif from 'images/chemex.gif';

export default class Home extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return(
            <div className="loadingBackground">
                <div className="chemexContainer">
                    <img src={loadingGif}/>
                </div>
            </div>
        )
    }
}
